@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

.D-main-box {
  border: 2px solid black;
  height: auto;
  width: 90%;
  border-radius: 10px;
  margin: auto;
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
  margin-top: 200px;
}

.D-left-box, .D-right-box {
  border: 2px solid black;
  height: auto;
  margin: 0;
  border-radius: 10px;
  box-sizing: border-box;
}

.D-left-box {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
}

.D-right-box {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  margin-top: 20px;
}

.donate-heading {
  font-size: 17px;
  color: #004A97;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 20px;
}

.seva-logo-image {
  height: 120px;
  width: 40%;
  margin: 20px auto;
  display: block;
}

.text-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seva-bharti-heading {
  font-size: 26px;
  color: #2E2E2E;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
}

.customer-details {
  color: #303030;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-left: 20px;
}

.Cus-name, .Cus-pay {
  height: 40px;
  width: calc(100% - 40px);
  margin: 10px 20px;
  border-radius: 5px;
  border: 1px solid grey;
  padding: 10px;
}

.name-email, .phone-pay {
  display: flex;
  flex-direction: column;
}

.D-Donatenow {
  background-color: #004A97;
  height: 40px;
  width: 80%;
  padding: 1px 6px;
  color: white;
  border: none;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 20px auto;
  display: block;
}

.payment-image {
  width: 50%;
  margin: 20px auto;
  display: block;
}

::placeholder {
  color: #97b1cd;
  opacity: 1;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

@media (min-width: 768px) {
  .D-main-box {
    width: 70%;
    flex-wrap: nowrap;
  }

  .D-left-box {
    width: 40%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0;
  }

  .D-right-box {
    width: 60%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 10px;
    margin-top: 0;
  }

  .name-email, .phone-pay {
    flex-direction: row;
  }

  .Cus-name, .Cus-pay {
    width: calc(50% - 30px);
    margin-left: 10px;
  }
}

@media (min-width: 468px) {
  .Cus-name, .Cus-pay {
    width: 80%;
  }
}

@media (max-width: 468px) {
  .Cus-name, .Cus-pay {
    width: 80%;
  }
}