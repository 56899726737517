.Anual-container{
    padding: 100px;
}
.Anual-container h1{
    color: var(--green);
    text-align: center;
    font-size: 30px;
    font-family: var(--font);
}
.Anual-container p{
    margin-top: 30px;
    text-align: center;
    color: black;
    font-family: var(--font);
}
.annual-heading-line{
    height: 3px;
    background-color: var(--orange);
    margin:   auto;
    margin-bottom: 30px;
    width: 280px;
}
.Annualprogram_imgcontainer img{
    height: 300px;
    width: 400px;
}
.Annualprogram_imgcontainer{
    /* border: 2px solid red; */
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    grid-gap: 20px 20px;
    column-gap: 30px;
}

@media screen and (max-width: 768px){
    .Annualprogram_imgcontainer img{
        height: 300px;
        width: 100%;
    } 
    .Anual-container{
        padding: 20px;
    }
    .Anual-container h1{
     
        font-size: 20px;
      
    }
    .Anual-container p{
        margin-top: 30px;
        text-align: center;
        color: black;
        font-size: 15px;
    }
    .annual-heading-line{
        height: 3px;
        background-color: var(--orange);
        margin:   auto;
        margin-bottom: 30px;
        width: 200px;
    }
}