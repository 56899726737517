@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body{
  min-height: 100vh;
}
nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  height: 100px;
  /* background: #3E8DA8; */
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 99;
}
nav .navbar{
  height: 100%;
  /* max-width: 1250px; */
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin: auto;
  /* background: red; */
  padding: 0 50px;
}
.navbar .logo a img{
    height: 90px;
}
.navbar .logo{
    margin-right: 20px;
    margin-left: 50px;
}
nav .navbar .nav-links{
  line-height: 70px;
  height: 100%;
  /* border: 2px solid red; */
  margin-left: 90px;
}
nav .navbar .links{
  display: flex;
  /* margin: 0 20px; */
  padding: 20px 0;
}
nav .navbar .links li{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0 14px;
}
nav .navbar .links li a{
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  /* border: 2px solid red; */
}
nav .navbar .links li a:hover{
    color: #ff8a00;
}
.tbutton {
  /* border: 2px solid red; */
  margin-top: 10px;
  margin-left: 150px;


}
 nav .navbar  .TButton{
  border: 2px solid var(--blue);
  padding: 5px 10px;
  margin-left:10px ;
  font-weight: 600;
  background-color: white;
  color: var(--blue);
  cursor: pointer;
} 
nav .navbar  .TButton:hover{
  background-color: var(--blue);
  color: var(--white);
}
.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow, .links li:hover .js-arrow1 .js-arrow2{
  transform: rotate(180deg);
  }

nav .navbar .links li .arrow{
  /* background: red; */
  height: 100%;
  width: 22px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  color: #000;
  transition: all 0.3s ease;
}
nav .navbar .links li .sub-menu{
  position: absolute;
  top: 70px;
  left: 0;
  line-height: 40px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  display: none;
  z-index: 2;
  border-top: 5px solid #ff8a00;
}
nav .navbar .links li:hover .htmlCss-sub-menu,
nav .navbar .links li:hover .js-sub-menu, nav .navbar .links li:hover .js-sub-menu1 ,
nav .navbar .links li:hover .js-sub-menu2{
  display: block;
}
.navbar .links li .sub-menu li{
  padding: 0 22px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
.navbar .links li .sub-menu a{
  color: #000;
  font-size: 15px;
  font-weight: 500;
  /* border: 2px solid red; */
}
.navbar .links li .sub-menu li:hover{
    border: 1px solid #ff8a00;
}
.navbar .links li .sub-menu a:hover{
    width: 100%;
    /* border: 1px solid #ff8a00; */
    color: #ff8a00;
}
.navbar .links li .sub-menu .more-arrow{
  line-height: 40px;
}
/* .navbar .links li .htmlCss-more-sub-menu{ */
  /* line-height: 40px; */
/* } */
.navbar .links li .sub-menu .more-sub-menu{
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 0 4px 4px 4px;
  z-index: 1;
  display: none;
}
.links li .sub-menu .more:hover .more-sub-menu{
  display: block;
}
.navbar .login-box{
  position: relative;
   height: 40px;
  width: 40px;
}
.navbar .login-box i{
  position: absolute;
  height: 100%;
  width: 100%;
  line-height: 40px;
  text-align: center;
  font-size: 35px;
  color: #E4C29D;
  /* font-weight: normal; */
  cursor: pointer;
  transition: all 0.3s ease;
}
.navbar .nav-links .sidebar-logo{
  display: none;
}
.navbar .bx-menu{
  display: none;
}

@media (max-width:920px) {
  nav .navbar{
    max-width: 100%;
    padding: 0 25px;
  }

  nav .navbar .logo a{
    font-size: 27px;
  }
  nav .navbar .links li{
    padding: 0 10px;
    white-space: nowrap;
  }
  nav .navbar .links li a{
    font-size: 15px;
  }
}
@media (max-width:800px){
  /* nav{ */
    /* position: relative; */
  /* } */
  .navbar .bx-menu{
    display: block;
    
  }
  nav .navbar .nav-links{
    position: fixed;
    top: 0;
    /* left: -90%; */
    display: block;
    /* max-width: 270px; */
    width: 70%;
    margin-left: -10px;
    background:  #fff;
    line-height: 40px;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
    z-index: 1000;
    overflow-y: scroll;
    margin-top: 100px;
  }
  .navbar .nav-links .sidebar-logo{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar-logo .logo-name{
    font-size: 25px;
    color: #000;
   
  }
    .sidebar-logo  i,
    .navbar .bx-menu{
      font-size: 25px;
      color: #000;
      
    }
  nav .navbar .links{
    display: block;
    margin-top: 20px;
    
  }
  nav .navbar .links li .arrow{
    line-height: 40px;
    
  }
nav .navbar .links li{
    display: block;
    
  }
nav .navbar .links li .sub-menu{
  position: relative;
  top: 0;
  box-shadow: none;
  display: none;
  
}
nav .navbar .links li .sub-menu li{
  border-bottom: none;

}
.navbar .links li .sub-menu .more-sub-menu{
  display: none;
  position: relative;
  left: 0;
}
.navbar .links li .sub-menu .more-sub-menu li{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow ,  .links li:hover .js-arrow1,
.links li:hover .js-arrow2{
  transform: rotate(0deg);
  }
  .navbar .links li .sub-menu .more-sub-menu{
    display: none;
  }
  .navbar .links li .sub-menu .more span{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .links li .sub-menu .more:hover .more-sub-menu{
    display: none;
  }
  nav .navbar .links li:hover .htmlCss-sub-menu,
  nav .navbar .links li:hover .js-sub-menu,
  nav .navbar .links li:hover .js-sub-menu1,
  nav .navbar .links li:hover .js-sub-menu2
  {
    display: none;
  }
.navbar .nav-links.show1 .links .htmlCss-sub-menu,
  .navbar .nav-links.show3 .links .js-sub-menu,
  .navbar .nav-links.show2 .links .js-sub-menu1,
  .navbar .nav-links.show4 .links .js-sub-menu2
  {
      display: block;
    }
    .navbar .nav-links.show1 .links .htmlcss-arrow,
    .navbar .nav-links.show2 .links .js-arrow1,
    .navbar .nav-links.show3 .links .js-arrow,
    .navbar .nav-links.show4 .links .js-arrow2{
        transform: rotate(180deg);
}
    /* .navbar .nav-links.show2 .links .more-arrow{
      transform: rotate(90deg);
    } */
}
@media (max-width:668px){
  nav .navbar .nav-links{
  max-width: 100%;
} 
.navbar .logo{
  margin-right: 0px;
  margin-left: -20px;
  margin-top: 10px;
}
  .navbar .bx-menu{
    margin-left: 45px;
  margin-top: px; 
    /* border: 2px solid red; */
    font-size: 35px;
  }
  .tbutton {
    /* border: 1px solid red; */
    /* margin-top: 40px; */
    margin-left: 25px;
  
  }
  nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100px;
    /* background: #3E8DA8; */
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 99;
    /* border: 2px solid blue; */
  }
    
}