@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

/* About us */
.head-jabout {
    text-align: center;
    color: #165a1d;
    font-size: 28px;
    font-family: "Poppins", sans-serif;
    padding: 10px;
}

.jabout-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.jabout-container img {
    width: 100%;
    max-width: 500px;
    height: auto;
}

.para-info {
    color: #222240;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-weight: 400;
    margin:  20px 60px;
}
.para-info a{
    text-decoration: none;
}

.btn-box {
    height: 40px;
    width: 160px;
    background-color: #ea7704;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 20px auto;
    display: block;
}

.our-history {
    border: 2px solid white;
    background-color: rgb(245, 240, 240);
    margin: 60px 20px;
    padding: 20px;
}

.head-history {
    text-align: center;
    color: #165a1d;
    font-family: "Poppins", sans-serif;
    font-size: 28px;
    margin-top: 30px;
}

.history-info {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    color: #222240;
    line-height: 1.5;
    margin: 20px;
    text-align: justify;
}

.ios {
    margin: 0 20px;
}

.vis-mis {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 40px 20px;
}

.vission, .mission, .Goal {
    border: 2px solid orange;
    border-radius: 60px;
    line-height: 1.5;
    margin: 20px;
    padding: 20px;
    flex: 1;
    max-width: 400px;
    box-sizing: border-box;
}

.vission img, .mission img , .Goal img {
    height: 200px;
    width: 200px;
    display: block;
    margin: 0 auto;
}


.vission p, .mission p, .Goal p   {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: #222240;
    text-align: center;
}

.mission ul li {
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    color: #222240;
    margin: 10px 0;
}





.facebook-embed {
    width: 100%;
    max-width: 400px; /* or adjust as needed */
    /* margin: 0 auto; */
    /* margin-left: 500px; */
}

.facebook-embed iframe {
    width: 100%;
    height: 500px; 
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
    .para-info, .our-history, .vis-mis {
        margin: 20px;
    }
}

@media (max-width: 768px) {
    .head-jabout, .head-history {
        font-size: 24px;
    }

    .para-info, .history-info, .vission p, .mission ul li {
        font-size: 16px;
    }

    .btn-box {
        width: 140px;
        height: 35px;
    }

    .vission, .mission {
        max-width: 100%;
        margin: 20px auto;
    }

    .jabout-container img {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .head-jabout, .head-history {
        font-size: 20px;
    }

    .para-info, .history-info, .vission p, .mission ul li {
        font-size: 14px;
        text-align: justify;
        /* justify-content: center; */
    }

    .para-info {
        text-align: justify;
    }

    .mission p {
        text-align: justify;
    }

    
    .history-info {
        /* border: 2px solid black; */
        /* height: 100px; */
        width: 250px;
        margin-left: -25px;
        padding: 0px;
        text-align: justify;
    }

    .btn-box {
        width: 120px;
        height: 30px;
    }

    .vission img, .mission img {
        height: 150px;
        width: 150px;
    }
}
