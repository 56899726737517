


.team-heading {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    color: var(--green);
    text-align: center;
    padding-top: 50px;
    font-weight: 600px;
    /* border: 2px solid red; */
}

.large-container {
    border: 2px solid rgb(255, 255, 255);
    height: auto;
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    /* border: 2px solid red; */
    /* padding:  0 100px; */
}

.large-container .small-cards {
    border: 2px solid var(--orange);
    height: 250px;
    width: 300px;
    /* margin-left: 50px; */
    margin-top: 50px;
    border-radius: 15px;
    background-color: var(--white);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.small-cards img {
    height: 115px;
    width: 100px;
    margin-left: 96px;
    margin-top: 25px;
    border-radius: 90px;
}

.pass-role p{
    text-align: center;
    font-size: 20px;
    color: #000;
    font-weight: bolder;  
    color: var(--green);
}

.pass-heading {
    font-size: 14px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    margin-top: 20px;
    /* margin-top: 20px; */
}


.pass-heading p {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    /* margin-top: 30px; */
}

.pass-information{
    padding:  10px 20px;
    /* text-align: center; */
}
.pass-information p {
 display: flex;
 padding: 5px 20px;
}
.pass-information p i{
    padding: 0px 4px;
    color: var(--orange);
}


/* Media Queries for Responsiveness */

@media (max-width: 1200px) {
    .large-container {
        height: auto; /* Allow height to adjust based on content */
    }

    .small-cards {
        width: 250px;
        margin-left: 20px;
        margin-top: 20px;
    }

    .small-cards img {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .pass-info p {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .heading {
        font-size: 18px;
    }

    .small-cards {
        width: 220px;
        height: 250px;
        padding: 0px;
    }

    .small-cards img {
        height: 90px;
        width: 80px;
    }

    .pass-heading  {
        font-size: 12px;
    }

    .pass-info  {
        margin-left: 0;
        font-size: 12px;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 16px;
    }

    .small-cards {
        width: 200px;
        height: 250px;
        margin-left: 10px;
        margin-top: 15px;
    }

    .small-cards img {
        height: 70px;
        width: 60px;
    }

    .pass-heading  {
        font-size: 10px;
        /* margin-left: 10px; */
    }

    .pass-info  {
        font-size: 10px;
        text-align: center;
    }
    .large-container{
        padding: 0px;
    }
}