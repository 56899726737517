@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.wrapper {
  width: 100%;
  position: relative;
  /* border: 2px solid red; */
  margin-bottom: 20px;
  padding: 30px;
}
.wrapper .carousel{
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% / 4) - 12px);
  /* overflow-x: auto; */
  gap: 16px;
  border-radius: 8px;
}
.carousel :where(.card, .img) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel .card {
  scroll-snap-align: start;
  height: 430px;
  list-style: none;
  background: #fff;
  cursor: pointer;
  /* padding-bottom: 20px; */
  flex-direction: column;
  border-radius: 8px;
}

.card{
    height: 100px;
    border: 1px solid rgb(192, 189, 189);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* height: 120px; */
}
/* .carousel .card .img {
  background: #8B53FF;
  height: 148px;
  width: 148px;
} */
.card .img img {
  width: 300px;
  height: 200px;
  /* border-radius: 50%; */
  object-fit: cover;
  border: 4px solid #fff;
  margin-top: 20px;
  padding: 5px;
}
.carousel .card h2 {
  font-weight: 600;
  font-size: 20px;
  color: var(--orange);
  text-align: center;
  /* border: 2px solid red; */
}
.carousel .card .description{
  /* border: 2px solid red; */
  text-align: justify;
  font-size: 15px;
  padding:  10px 15px;
}
.carousel .card span {
  color: var(--blue);
  font-size: 1.31rem;
}

.program-read-more-button {
    border: 2px solid var(--orange);
    color: var(--white);
    height: 40px;
    width: 100%;
    border-radius: 5px;
    font-size: 12px;
    margin-top: 10px;
    justify-content: center;
    background-color: var(--orange);
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 40px;
}
@media screen and (max-width: 900px) {
  .wrapper .carousel {
    grid-auto-columns: calc((100% / 2) - 9px);
  }
}
@media screen and (max-width: 600px) {
  .wrapper .carousel {
    grid-auto-columns: 100%;
  }
}

@media screen and (max-width: 480px) {
  .wrapper .carousel {
    /* border: 2px solid blue; */
    width: 100%;
    display: grid;
  grid-auto-flow: row;
    
  }
  .carousel .card {
    height: 460px;
    width: 100%;
  }
  .wrapper {
    /* border: 2px solid blue; */
    width: 98%;
  }
  
}