/* Filename - App.css*/
.List {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 60px;
} 
table {
	margin-top: -35rem;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	/* border: 2px solid forestgreen; */
	width: 800px;
	height: 200px;
}

th {
	/* background-color: #0A8C18; */
    background-color: #E67E22;
	color: white;
	padding: 10px;
	/* border-bottom: 1px solid black; */
}

td {
	text-align: center;
	/* border-bottom: 1px solid rgb(236, 233, 233); */
}
tr:hover{
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}