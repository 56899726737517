/* src/PageSlider.css */
.page-slider {
    width: 100%;
    /* height: 800px;  */
    padding: 0;
    margin: 0;
  }
  
  .page {
    height: 100%; /* Ensure the page takes full height */
    display: flex;
    align-items: center; /* Center align content */
    justify-content: center; /* Center align content */
  }
  
  .page-image {
    width: 100%; /* Make image fit the width of the slider */
    height: 600px; /* Make image fit the height of the slider */
    /* object-fit: cover; Ensure the image covers the slider area */
    /* border-radius: 10px; */
  }
  @media screen and (max-width: 480px){
  .page-image {
    margin-top: 5px;
  height: 250px;
  /* padding: 5px; */
  }

  }