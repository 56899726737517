:root {
  --blue: #0A3153;
  --white: white;
  --orange: #E67E22;
  --green: #0A8C18;
}


.home-head{
  color: #0a8c18;
  font-size: 30px;
  /* border: 2px solid red; */
}

@media (max-width:468px){
  *{
    /* margin-left: 100px; */
    padding: 0;
    box-sizing: border-box;
    /* border: 2px solid red; */
    
}
}








