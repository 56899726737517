.approach-container{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
/* flex-wrap: wrap; */
    grid-gap: 10px;
    background-color: #0A3153;
    padding: 30px;
    /* border: 2px solid red; */
}
.approach-content{
    margin-top: 10px;
}
.approach-img img{
   text-align: center;
   margin: 0px 100px;
   height: 400px;
   width: 400px;
   /* border: 2px solid red; */
}
.approach-content h1{
    color:  white;
    font-size: 30px;
    margin: 10px 20px;
}
.approach-content ul {
    display: flex;
    flex-direction: row;
    align-items: center;
 
}
.approach-content ul li{
    margin: 20px;
    color: #E67E22;
    font-size: 20px;
    list-style: none;
}
.approach-content button{
    margin-top: 20px;
    padding: 12px 30px;
    background-color: #E67E22;
    color: #fff;
    border: none;
    border-radius: 10px;
    margin: 10px 20px;
    font-size: 15px;
}
.approach-container p{
    display: flex;
    flex-wrap: wrap;
    line-height: 25px;
    text-align: justify;
    color: white;
    margin: 10px 20px;
    /* border: 2px solid black; */
    /* width: 100%; */
}

@media (max-width: 500px) {

    .approach-container{
        display: flex;
        flex-direction: column;
    }
.approach-content ul{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.approach-content ul li{
    margin: 10px;
    color: #FF5722;
    font-size: 20px;
    list-style: none;
}
.approach-img img{
    text-align: center;
    margin: 0px;
    height: 300px;
    width: 300px;
}
.approach-container p{
     
    margin: 10px 0px;

}
}