@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

/* Annual CSS */

.annual-main-container {
    padding: 20px;
}

.A-heading {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    color: #165a1d;
    text-align: center;
}

.A-large-container {
    border: 2px solid rgb(255, 255, 255);
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    max-width: 960px; /* Center the container */
    margin: 0 auto;
}

.sevapathimage {
    width: 100%;
}

.A-small-cards {
    border: 2px solid #F0FFFF;
    height: auto;
    border-radius: 5px;
    background-color: #f5e4ccc5;
    flex: 0 1 30%;
    margin: 10px;
    box-sizing: border-box;
    transition: transform 0.2s;
}

.A-small-cards:hover {
    transform: scale(1.05);
}

.A-small-cards img {
    height: auto;
    width: 100%;
    max-height: 270px;
    display: block;
    margin: 20px auto 0;
}

.A-pass-heading {
    font-size: 14px;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.A-pass-heading p {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    margin-top: 30px;
    color: #E67E22;
}

/* Media Queries for Responsiveness */

@media (max-width: 1200px) {
    .A-small-cards {
        flex: 0 1 45%; /* Two cards in a row */
    }

    .A-heading {
        font-size: 22px;
    }
}

@media (max-width: 768px) {
    .A-small-cards {
        flex: 0 1 100%; /* Full width for each card */
        margin-left: 0;
        margin-right: 0;
    }

    .A-heading {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .A-heading {
        font-size: 18px;
    }

    .A-pass-heading p {
        font-size: 16px;
    }
}